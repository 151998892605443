.popUp {
    display: flex;
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.486);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    left: 0;
    z-index: 1;
}

.popUpRow {
    display: flex;
    width: 100%;
}

.side {
    display: flex;
    flex-grow: 1;
    height: 100%;
}

.top {
    width: 100%;
}

.main {
    display: flex;
}