.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 360px;
}

@media only screen and (max-width: 920px) {
    .container {
        flex-direction: row;
        width: 100%;

        img {
            width: 50%;
        }

        div {
            padding: 20px;
            width: 50%;
        }
    }
}

@media only screen and (max-width: 600px) {
    .container {
        flex-direction: column;
        width: 100%;

        img {
            width: 100%;
        }

        div {
            padding: 0;
            width: 95%;
        }

    }
}