.container {
    display: flex;
    width: 250px;
    height: 350px;
    flex-direction: column;
    align-items: center;

    * {
        margin: 0;
    }
}

.productImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.productButton {
    width: 100%;
    display: flex;
    justify-content: end;
}

.skeleton {
    animation: skeletonLoading 1s linear infinite alternate;
}

@keyframes skeletonLoading {
    from {
        background-color: hsl(200, 20%, 70%);
    }

    to {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeletonImage {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    animation: skeletonLoading 1s linear infinite alternate;
}

.skeletonTextL {
    width: 50%;
    height: 2em;
    margin: .3rem;
    border-radius: 5px;
    animation: skeletonLoading 1s linear infinite alternate;
}

.skeletonTextS {
    width: 35%;
    height: 1em;
    margin: .3rem;
    border-radius: 3px;
    animation: skeletonLoading 1s linear infinite alternate;
}

.cardStyle {
    max-width: 273px;

}