.input {
    border-radius: 5px;
    font-size: larger;
    border: solid brown;
}

.errorIn {
    border-color: red;
}

.main {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: rgba(177, 253, 253, 0.438);
    padding: 5px;
    border-radius: 5px;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: larger;

    @media only screen and (max-width: 390px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.inputDiv {
    display: flex;
    flex-direction: column;

    label {
        color: red;
        font-size: smaller;
        margin: 0;
    }

}