.form {
    width: 420px;

    @media only screen and (max-width: 440px) {
        width: auto;
    }
}

.error {
    color: red;
}