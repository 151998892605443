.container {
    display: flex;
}

.cart {
    margin-right: 30px;
    display: flex;
    flex-direction: column;
}

.order {
    margin-left: 30px;
    height: 100%;

    h3 {
        margin: 0;
    }
}

.btnDiv {
    display: flex;
    padding-top: 30px;
    justify-content: end;
    width: 100%;
}

.spacer {
    flex-grow: 1;
}