.button {
    font-size: large;
    border-radius: 5px;
    padding: 5px;

    * {
        margin: 0;
        padding: 0;
    }
}


.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.active {
    background-color: brown;
    border: solid brown;
    color: white;

}

.active:hover {
    transform: scale(1.05);
}

.active:active {
    transform: scale(0.95);
}

.disabled {
    background-color: grey;
    border: solid grey;
    color: white;

}

.loading {
    background-color: lightblue;
    border: solid lightblue;
    color: rgb(56, 56, 255);

    span {
        margin-right: .5rem;
    }
}

.animateDisabled {
    animation: disabledAnimation 400ms;
}

@keyframes disabledAnimation {
    25% {

        transform: translate(-5px);
        border: solid red;
        background-color: red;
    }


    75% {
        transform: translate(5px);
    }
}