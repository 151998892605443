.load {
    border: solid blue;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1.0s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

}

.load_1 {
    width: 10px;
    height: 10px;
    border-width: 1px;
    margin-right: 10px;
}

.load_5 {
    width: 50px;
    height: 50px;
    border-width: 5px;
    margin-right: 10px;
}

.load_10 {
    width: 100px;
    height: 100px;
    border-width: 10px;
    margin-right: 10px;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}