.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 360px;

    div {
        margin: 20px;
    }
}

@media only screen and (max-width: 1350px) {
    .container {
        width: 100%;
        align-items: center;
        justify-content: space-evenly;
    }
}

.error {
    color: red;
}

.notification {
    color: rgb(226, 226, 0);
}

.image {
    width: 100%;
}