.cart {
    position: sticky;
    top: 5%;
    width: 300px;
    flex-direction: column;
}

.addButtonDiv {
    position: absolute;
    top: 10%;
    right: 5%;
}