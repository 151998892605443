.container {
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    background-color: brown;
    min-height: 50px;
}

.header {
    display: flex;
    justify-content: space-between;
    max-width: 1600px;
    width: 100%;
    align-items: center;
    color: white;
    margin-left: 15px;
    margin-right: 15px;

    * {
        padding: 0;
        margin: 0;
    }
}

.user {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logOutBtn {
    background-color: antiquewhite;
    font-size: small;
    padding: 5px;
    border-radius: 5px;
    color: black;
    border-color: antiquewhite;
}